<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
            <template v-slot:body>
                <b-overlay :show="loading">
                    <b-row>
                    <b-col lg="12" sm="12">
                        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                        <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                            <ValidationProvider name="Room Type" vid="room_type_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="5"
                                label-for="room_type_id"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('elearning_config.room_type')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="roomRentData.room_type_id"
                                :options="roomTypeList"
                                id="room_type_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Org Type" vid="org_type_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="5"
                                label-for="org_type_id"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('elearning_accommodation.org_type')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="roomRentData.org_type_id"
                                :options="orgTypeList"
                                id="org_type_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <!-- <ValidationProvider name="Rent" vid="rent" rules="required|min_value:0">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="5"
                                    label-for="rent"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('elearning_accommodation.rent')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                    id="rent"
                                    v-model="roomRentData.rent"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider> -->
                            <ValidationProvider name="Rent" vid="rent" rules="required" v-slot="{ errors }">
                                <b-form-group
                                label-for="rent"
                                label-cols-sm="5"
                                >
                                <template v-slot:label>
                                    {{ $t('elearning_accommodation.rent') }}
                                </template>
                                <b-form-input
                                    id="rent"
                                    v-model="roomRentData.rent"
                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                            <!-- <ValidationProvider name="VAT/Tax (%)" vid="vat_tax" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="5"
                                    label-for="vat_tax"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('elearning_config.vat_tax')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    type="number"
                                    id="vat_tax"
                                    v-model="roomRentData.vat_tax"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider> -->
                            <ValidationProvider name="Remarks (En)"  vid="remarks" rules="">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="5"
                                    label-for="remarks"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('elearning_config.remarks_en')}}
                                    </template>
                                    <b-form-textarea
                                        rows="2"
                                        id="remarks"
                                        v-model="roomRentData.remarks"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Remarks (Bn)"  vid="remarks_bn" rules="">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="5"
                                    label-for="remarks_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('elearning_config.remarks_bn')}}
                                    </template>
                                    <b-form-textarea
                                        rows="2"
                                        id="remarks_bn"
                                        v-model="roomRentData.remarks_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            <div class="row">
                            <div class="col-sm-3"></div>
                            <div class="col text-right">
                                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                &nbsp;
                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                            </div>
                            </div>
                        </b-form>
                        </ValidationObserver>
                    </b-col>
                    </b-row>
                </b-overlay>
            </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { roomRentStore, roomRentUpdate } from '../../api/routes'

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    created () {
        if (this.id) {
            const tmp = this.getRoomRentData()
            this.roomRentData = tmp
        }
    },
    mounted () {
        core.index()
    },
    data () {
        return {
        loading: false,
        saveBtnName: this.id ? this.$t('elearning_config.update') : this.$t('globalTrans.save'),
        roomRentData: {
            id: '',
            room_type_id: 0,
            org_type_id: 0,
            rent: 0,
            vat_tax: 0,
            remarks: '',
            remarks_bn: '',
            status: 1
        }
        }
    },
    computed: {
        roomTypeList: function () {
          return this.$store.state.TrainingElearning.commonObj.roomTypeSetupList.filter(item => item.status === 1)
        },
        orgTypeList () {
            const orgTypeList = [
            { value: 1, text: this.$i18n.locale === 'en' ? 'Government Organization' : 'সরকারি প্রতিষ্ঠান', text_en: 'Government Organization', text_bn: 'সরকারি প্রতিষ্ঠান' },
            { value: 2, text: this.$i18n.locale === 'en' ? 'Non Government Organization' : 'বেসরকারি প্রতিষ্ঠান', text_en: 'Private Organization', text_bn: 'বেসরকারি প্রতিষ্ঠান' }
        ]
            return orgTypeList
        }
    },
    watch: {
    },
    methods: {
        getRoomRentData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        async createData () {
            this.loading = true
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadinState = { loading: false, listReload: false }

            if (this.roomRentData.id) {
                result = await RestApi.putData(trainingElearningServiceBaseUrl, `${roomRentUpdate}/${this.id}`, this.roomRentData)
            } else {
                result = await RestApi.postData(trainingElearningServiceBaseUrl, roomRentStore, this.roomRentData)
            }

            loadinState.listReload = true

            this.$store.dispatch('mutateCommonProperties', loadinState)

            if (result.success) {
                this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
                this.$toast.success({
                title: 'Success',
                message: this.id ? this.$t('elearning_config.update_msg') : this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
                })

                this.$bvModal.hide('modal-4')
            } else {
                this.$refs.form.setErrors(result.errors)
            }
            this.loading = false
        }
    }
}
</script>
